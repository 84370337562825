.root {
  @apply p-2;
  @apply flex;
  @apply w-20;
  @apply h-20;
  @apply text-sm;
  @apply border-2;
  @apply font-medium;
  @apply text-center;
  @apply text-common;
  @apply items-center;
  @apply rounded-full;
  @apply justify-center;

  @apply data-[variant="filled"]:data-[color='orange']:bg-orange;
  @apply data-[variant="filled"]:data-[color='yellow']:bg-yellow;
  @apply data-[variant="filled"]:data-[color='green']:bg-green;
  @apply data-[variant="filled"]:data-[color='gray']:bg-white;
  @apply data-[variant="filled"]:border-primary/20;

  @apply data-[variant="outlined"]:data-[color='gray']:border-primary/20;
  @apply data-[variant="outlined"]:data-[color='yellow']:border-yellow;
  @apply data-[variant="outlined"]:data-[color='green']:border-green;
  @apply data-[variant="outlined"]:data-[color='orange']:border-orange;
  @apply data-[variant="outlined"]:bg-white;
}
