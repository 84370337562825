.root {
  @apply grid;
  @apply w-full;
  @apply grid-cols-4;
}

.root .session {
  @apply py-3;
  @apply flex;
  @apply gap-3;
  @apply flex-col;
  @apply relative;
  @apply items-center;

  @apply before:h-1;
  @apply before:w-full;
  @apply before:absolute;
  @apply before:bottom-10;
  @apply before:content-[''];
  @apply before:bg-primary/20;
  @apply before:-translate-y-2.5;

  @apply before:last:w-1/2;
  @apply before:first:w-1/2;
  @apply before:first:left-1/2;
  @apply before:last:right-1/2;

  @apply data-[current=true]:rounded-lg;
  @apply data-[current=true]:bg-gray-100;
}

.root .session .date {
  @apply h-8;
  @apply flex;
  @apply text-xs;
  @apply mx-auto;
  @apply text-center;
  @apply items-center;
  @apply text-primary;
  @apply font-semibold;
  @apply justify-center;
}

.root .session .badge {
  @apply z-10;
  @apply relative;
}

.root .session .badge .icon {
  @apply w-6;
  @apply h-6;
  @apply text-primary/20;
}
