.root {
  @apply flex;
  @apply gap-10;
  @apply flex-col;
}

.root .dates {
  @apply flex;
  @apply gap-2;
  @apply justify-center;
}

.root .symptoms {
  @apply w-full;
  @apply grid auto-rows-fr grid-flow-row gap-4;
}

.root .symptoms .symptom {
  @apply min-h-[30px];
  @apply flex items-center gap-4;
}

.root .symptoms .symptom .bar {
  @apply h-4;
  @apply flex-grow;
  @apply rounded-full;
  @apply bg-gray-100;
  @apply flex-shrink-0;
}

.root .symptoms .symptom .bar .progress {
  @apply h-full;
  @apply relative;
  @apply rounded-full;
  @apply transition-all;
  @apply w-[var(--width)];
  @apply bg-primary/[var(--opacity)];
  @apply data-[color='orange']:bg-orange;
  @apply data-[color='yellow']:bg-yellow;
  @apply data-[color='green']:bg-green;
}

.root .symptoms .symptom .bar .progress .value {
  @apply font-semibold;
  @apply text-xs text-primary;
  @apply absolute right-0 -top-4;
}

.root .symptoms .symptom .title {
  @apply w-1/3;
  @apply font-medium;
  @apply text-xs text-right text-primary;
  /*noinspection CssInvalidPropertyValue*/
  text-wrap: balance;
}

.icon {
  @apply w-6;
  @apply h-6;
  @apply m-auto;
  @apply text-primary/20;
}
